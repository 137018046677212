export const chartColors = {
  strongBlue: '#232D4B',
  mineralGreen: '#00AA9B',
  neutralBlue: '#005573',
  accentRed: '#F04641',
  primaryColorShade: '#836AF9',
  blueLightColor: '#84D0FF',
  greyColor: '#4F5D70',
  warningLightColor: '#FDAC34',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  lineChartPrimary: '#666ee8',
  infoColorShade: '#299AFF',
  blueColor: '#2c9aff',
  yellowColor: '#ffe800',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
  noneColor: 'transparnt',
}

// colors
export const chartColorsRadar = {
  column: {
    series1: '#00E19B',
    series2: '#00AA9B',
    series3: '#005573',
    bg: '#CCE3E4',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#005573',
  },
}
