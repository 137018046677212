import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import aiops from './routes/aiops/homepage'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'aiops-auth-login' } },
    ...aiops,
    ...pages,
    {
      path: '*',
      redirect: { name: 'aiops-auth-login' },
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn && to.name !== 'aiops-auth-login') {
  //     return next({ name: 'aiops-auth-login' })
  //   }

  //   // If logged in => not authorized
  //   return next({ name: 'aiops-model-manager' })
  // }
  if (isLoggedIn && to.name === 'aiops-auth-login') {
    return next({ name: 'aiops-home' })
  }
  if (to.meta.protectedRoute) {
    if (!isLoggedIn && to.name !== 'aiops-auth-login') {
      return next({ name: 'aiops-auth-login' })
    }
    const toData = to
    if (toData.name === 'aiops-detail-similar-ticket') {
      toData.meta.breadCrumb.root[0].name = `Ticket ${toData.params.id}`
    }
    store.dispatch('productAiops/dataBreadCrumb', toData.meta.breadCrumb)
    return next()
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'block'
  }
})

export default router
