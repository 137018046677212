const envBackendHost = https://teis-eulen.westeurope.cloudapp.azure.com/teis-backend/ || 'https://teis-eulen.westeurope.cloudapp.azure.com/teis-backend/'
const envTicketsHost = https://teis-eulen.westeurope.cloudapp.azure.com/teis-service-now/ || 'https://teis-eulen.westeurope.cloudapp.azure.com/teis-service-now/'

export default {
  // Endpoints
  // LOGIN RESOURCE
  loginAiopsEndpoint: `${envBackendHost}login`,
  refreshEndpoint: `${envBackendHost}login/refreshtoken`,
  // TICKETS RESOURCE
  ticketsAiops: `${envTicketsHost}ticket/page`,
  ticketDetail: `${envTicketsHost}ticket`,
  totalTickets: `${envTicketsHost}ticket/total`,
  similarTickets: `${envTicketsHost}ticket/similarto`,
  vectorTicket: `${envTicketsHost}ticket/embedding`,
  assignmentGroup: `${envTicketsHost}ticket/groups`,
  predictionModel: `${envBackendHost}predict`,
  applyArea: `${envTicketsHost}ticket/applyarea`,
  categoryTree: `${envTicketsHost}ticket/categories`,
  // MODEL RESOURCE
  modelManager: `${envBackendHost}model/findbytype`,
  modelsTree: `${envBackendHost}model/tree`,
  modelSubcategory: `${envBackendHost}model/findbyparent`,
  modelclass: `${envBackendHost}modelclass`,
  switchModel: `${envBackendHost}model/switchenabled`,
  // DATA SOURCE
  configHost: `${envTicketsHost}config`,
  configList: `${envTicketsHost}config/list`,
  // GROUP RESOURCE
  groups: `${envBackendHost}groups`,
  // USER RESOURCE
  users: `${envBackendHost}users`,
  passwordRule: `${envBackendHost}config`,
  // KNOWLEDGE BASE
  knowledgeBase: `${envTicketsHost}knowledge`,

  // params to login
  clientId: 'aiops-service',
  grant_type: 'password',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  contentType: 'Content-Type',
  content_type: 'application/json',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessTokenAiOpsApp',
  storageRefreshTokenKeyName: 'refreshTokenAiOpsApp',
}
