import useJwt from '@/auth/aiops/useJwt'
// import utilsAiops from '@/@core/utils/aiops/utils'

// const { formatDate } = utilsAiops()

export default {
  namespaced: true,
  state: {
    proccessCreateuser: true,
  },
  getters: {},
  mutations: {
    proccessCreateuser(state, payload) {
      state.proccessCreateuser = payload
    },
  },
  actions: {
    findUsers({ commit }, query) {
      return new Promise((resolve, reject) => {
        useJwt.findUsers(query)
          .then(response => {
            const { data } = response
            const items = []
            data.forEach(item => {
              const dataTable = {
                id: item.id,
                username: item.username,
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                role: item.groups.length > 0 ? item.groups[0].name : undefined,
                enabled: item.enabled,
              }
              items.push(dataTable)
            })
            commit('proccessCreateuser', true)
            resolve({ items, data })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getUsers() {
      return new Promise((resolve, reject) => {
        useJwt.getUsers()
          .then(response => {
            const { data } = response
            const items = []
            data.forEach(item => {
              const dataTable = {
                id: item.id,
                username: item.username,
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                role: item.groups.length > 0 ? item.groups[0].name : undefined,
                enabled: item.enabled,
              }
              items.push(dataTable)
            })
            resolve({ items, data })
          })
          .catch(error => {
            const { data } = error
            reject(data.data)
          })
      })
    },
    createUsers({ commit }, dataUser) {
      return new Promise((resolve, reject) => {
        useJwt.createUsers(dataUser)
          .then(response => {
            const { data } = response
            commit('proccessCreateuser', true)
            resolve(data)
          })
          .catch(error => {
            const { data } = error
            reject(data.data)
          })
      })
    },
    updateUser({ commit }, dataUser) {
      return new Promise((resolve, reject) => {
        useJwt.updateUser(dataUser)
          .then(response => {
            const { data } = response
            commit('proccessCreateuser', true)
            resolve(data)
          })
          .catch(error => {
            const { data } = error
            reject(data.data)
          })
      })
    },
    getUserById({ commit }, id) {
      return new Promise((resolve, reject) => {
        useJwt.getUserById(id)
          .then(response => {
            const { data } = response
            commit('proccessCreateuser', true)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getGroups() {
      return new Promise((resolve, reject) => {
        useJwt.getGroups()
          .then(response => {
            const { data } = response
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getMembersGroups({ commit }, id) {
      return new Promise((resolve, reject) => {
        useJwt.getMembersGroups(id)
          .then(response => {
            const { data } = response
            commit('proccessCreateuser', true)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getPasswordRule() {
      return new Promise((resolve, reject) => {
        useJwt.getPasswordRule()
          .then(response => {
            const { data } = response
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
