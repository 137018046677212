import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// eslint-disable-next-line import/no-cycle
import productAiops from './product-aiops'
import userRolesAiops from './user-roles-aiops'
import knowledgeBase from './knowledge-base'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    productAiops,
    userRolesAiops,
    knowledgeBase,
  },
  strict: process.env.DEV,
})
