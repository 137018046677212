export default [
  {
    path: '/aiops-homepage',
    name: 'aiops-homepage',
    component: () => import('@/views/product/aiops/Homepage.vue'),
    children: [
      {
        path: '/aiops/authentication/login',
        name: 'aiops-auth-login',
        component: () => import('@/views/product/aiops/pages/authentication/Login.vue'),
        meta: {
          layout: 'full',
          protectedRoute: false,
        },
      },
      {
        path: '/aiops/home',
        name: 'aiops-home',
        component: () => import('@/views/product/aiops/pages/tickets-table/TicketsTable.vue'),
        meta: {
          layout: 'full',
          protectedRoute: true,
        },
      },
      {
        path: '/data-graphics',
        name: 'aiops-home-data-graphics',
        component: () => import('@/views/product/aiops/pages/data-graphics/TicketsGraphics.vue'),
        meta: {
          layout: 'full',
          protectedRoute: true,
        },
      },
      {
        path: '/ticket-detail/:id',
        name: 'aiops-detail-similar-ticket',
        component: () => import('@/views/product/aiops/pages/detail-similar-ticket/DetailSimilarTicket.vue'),
        meta: {
          layout: 'full',
          protectedRoute: true,
          action: 'admin',
          breadCrumb: {
            name: 'Home',
            icon: 'icons8_home_neutral_blue',
            root: [
              {
                name: 'Tickets',
              },
            ],
          },
        },
      },
      {
        path: '/model-manager/classification-model',
        name: 'aiops-classification-model',
        component: () => import('@/views/product/aiops/pages/model-manager/ModelManager.vue'),
        meta: {
          layout: 'full',
          protectedRoute: true,
          breadCrumb: {
            name: 'Classification model',
            icon: 'icons8_tag_window_1',
            root: [
              {
                name: 'Model manager - Classification model',
              },
            ],
          },
        },
      },
      {
        path: '/model-manager/business-automation',
        name: 'aiops-business-automation',
        component: () => import('@/views/product/aiops/pages/business-automation/BusinessAutomation.vue'),
        meta: {
          layout: 'full',
          protectedRoute: true,
          breadCrumb: {
            name: 'Business automation',
            icon: 'aiops_business_automation',
            root: [
              {
                name: 'Model manager - Business automation',
              },
            ],
          },
        },
      },
      {
        path: '/setting/data-source',
        name: 'aiops-setting-data-source',
        component: () => import('@/views/product/aiops/pages/data-source/DataSourcePage.vue'),
        meta: {
          layout: 'full',
          protectedRoute: true,
          breadCrumb: {
            name: 'Data Sources',
            icon: 'icons8_e-learning',
            root: [
              {
                name: 'Settings - Data Sources',
              },
            ],
          },
        },
      },
      {
        path: '/setting/users',
        name: 'aiops-setting-users',
        component: () => import('@/views/product/aiops/pages/users/Users.vue'),
        meta: {
          layout: 'full',
          protectedRoute: true,
          breadCrumb: {
            name: 'Users',
            icon: 'users_darker',
            root: [
              {
                name: 'Settings - Users',
              },
            ],
          },
        },
      },
      {
        path: '/setting/roles',
        name: 'aiops-setting-roles',
        component: () => import('@/views/product/aiops/pages/roles/Roles.vue'),
        meta: {
          layout: 'full',
          protectedRoute: true,
          breadCrumb: {
            name: 'Roles',
            icon: 'icons8_admin_settings_male_darker',
            root: [
              {
                name: 'Settings - Roles',
              },
            ],
          },
        },
      },
      {
        path: '/setting/knowledge-base',
        name: 'aiops-setting-knowledge-base',
        component: () => import('@/views/product/aiops/pages/knowledge-base/KnowledgeBase.vue'),
        meta: {
          layout: 'full',
          protectedRoute: true,
          breadCrumb: {
            name: 'Knowledge Base',
            icon: 'list_medium',
            root: [
              {
                name: 'Settings - Knowledge Base',
              },
            ],
          },
        },
      },
    ],
  },
]
