import { $themeColors } from '@themeConfig'
import { chartColors } from '@/@core/utils/aiops/chartConfig'
import jwtConfig from '@/@core/auth/aiops/jwtDefaultConfig'
import useJwt from '@/auth/aiops/useJwt'
import utilsAiops from '@/@core/utils/aiops/utils'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

const { formatDate } = utilsAiops()

export default {
  namespaced: true,
  state: {
    aiops: {
      logged: false,
      showWelcome: false,
      user: '',
    },
    dataBreadCrumb: {},
    pieChart: {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 50,
        legend: { display: false },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[0].labels[tooltipItem.index] || ''
              const value = data.datasets[0].data[tooltipItem.index]
              const output = ` ${label} : ${value} %`
              return output
            },
          },
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: chartColors.accentRed,
          bodyFontColor: chartColors.strongBlue,
        },
      },
      data: {
        datasets: [
          {
            labels: [],
            data: [],
            backgroundColor: [chartColors.strongBlue, chartColors.mineralGreen, chartColors.neutralBlue, chartColors.accentRed, $themeColors.light],
            borderWidth: 2,
            pointStyle: 'rectRounded',
          },
        ],
      },
    },
    refreshToken: undefined,
    configuredService: false,
    totalTickets: 0,
    configList: [],
    dataChartsDefault: [
      {
        idTicket: 25971,
        ticketSourceId: '522520d31bf33010c5bcebd5624bcbb0',
        ticketSourceNumber: 'INC0010079',
        createdDate: '2021-11-18T14:30:00',
        modifiedDate: '2021-11-18T14:30:00',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'fallo impresora',
        description: 'La fotocopiadora de la sala de presidencia no funciona correctamente. Se bloquea y no hace copias.',
        categoryLevel2: 'Software_PC',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-18T14:30:37',
        dateModifiedSourceArea: '2021-11-18T14:30:39',
        events: [],
      },
      {
        idTicket: 25970,
        ticketSourceId: '781a0cdb1bb33010c5bcebd5624bcb26',
        ticketSourceNumber: 'INC0010078',
        createdDate: '2021-11-18T12:31:39',
        modifiedDate: '2021-11-18T12:31:39',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'fallo fotocopiadora',
        description: 'La fotocopiadora de la sala de presidencia no funciona correctamente. Se bloquea y no hace copias.',
        categoryLevel2: 'Software_PC',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-18T12:32:41',
        dateModifiedSourceArea: '2021-11-18T12:32:44',
        events: [],
      },
      {
        idTicket: 25969,
        ticketSourceId: '1d3e43721b373010c5bcebd5624bcbf4',
        ticketSourceNumber: 'INC0010077',
        createdDate: '2021-11-17T13:31:36',
        modifiedDate: '2021-11-17T13:31:36',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'fallo impresora',
        description: 'La fotocopiadora de la sala de presidencia no funciona correctamente. Se bloquea y no hace copias.',
        categoryLevel2: 'Software_PC',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-17T13:32:40',
        dateModifiedSourceArea: '2021-11-17T13:32:43',
        events: [],
      },
      {
        idTicket: 25968,
        ticketSourceId: '92ecdaea1bf33010c5bcebd5624bcb72',
        ticketSourceNumber: 'INC0010076',
        createdDate: '2021-11-16T15:17:45',
        modifiedDate: '2021-11-16T15:17:45',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'posible virus',
        description: 'He recibido este correo que creemos puede ser un virus.',
        categoryLevel2: 'Software_PC',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-16T15:18:37',
        dateModifiedSourceArea: '2021-11-16T15:18:39',
        events: [],
      },
      {
        idTicket: 25967,
        ticketSourceId: '3e1292e21bf33010c5bcebd5624bcbfa',
        ticketSourceNumber: 'INC0010075',
        createdDate: '2021-11-16T14:30:32',
        modifiedDate: '2021-11-16T15:21:56',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'nueva asignación permisos',
        description: 'Podeis asignarle al usuario IBER0001 los mismos permisos en La3 que el usuario F329?',
        categoryLevel2: 'SIEBEL',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'RESTO',
        originalArea: null,
        dateModifiedArea: null,
        dateModifiedSourceArea: '2021-11-16T15:21:57',
        events: [],
      },
      {
        idTicket: 25966,
        ticketSourceId: '8b3fcae21bf33010c5bcebd5624bcb70',
        ticketSourceNumber: 'INC0010074',
        createdDate: '2021-11-16T14:17:59',
        modifiedDate: '2021-11-16T14:17:59',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'fallo impresora',
        description: 'La fotocopiadora de la sala de presidencia no funciona correctamente. Se bloquea y no hace copias.',
        categoryLevel2: 'Hardware_PC',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-16T14:18:38',
        dateModifiedSourceArea: '2021-11-16T14:18:38',
        events: [],
      },
      {
        idTicket: 25965,
        ticketSourceId: '219e0aa21bf33010c5bcebd5624bcbd7',
        ticketSourceNumber: 'INC0010073',
        createdDate: '2021-11-16T14:15:09',
        modifiedDate: '2021-11-16T14:15:09',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'asignar permisos de nuevo',
        description: 'Podeis asignarle al usuario IBER0001 los mismos permisos en La3 que el usuario F329?',
        categoryLevel2: 'SIEBEL',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'RESTO',
        originalArea: 'MICRO',
        dateModifiedArea: '2021-11-16T14:16:37',
        dateModifiedSourceArea: '2021-11-16T14:16:38',
        events: [],
      },
      {
        idTicket: 25964,
        ticketSourceId: '2acdc2ea1b733010c5bcebd5624bcb70',
        ticketSourceNumber: 'INC0010072',
        createdDate: '2021-11-16T14:11:40',
        modifiedDate: '2021-11-16T14:11:40',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'asignar permisos',
        description: 'Podeis asignarle al usuario IBER0001 los mismos permisos en La3 que el usuario F329?',
        categoryLevel2: 'SIEBEL',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'RESTO',
        originalArea: 'MICRO',
        dateModifiedArea: '2021-11-16T14:12:37',
        dateModifiedSourceArea: '2021-11-16T14:12:39',
        events: [],
      },
      {
        idTicket: 25963,
        ticketSourceId: '03e03d6a1b733010c5bcebd5624bcb8d',
        ticketSourceNumber: 'INC0010071',
        createdDate: '2021-11-16T12:05:33',
        modifiedDate: '2021-11-16T12:05:33',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'virus en email',
        description: 'virus en correo\t',
        categoryLevel2: 'CRM',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'RESTO',
        originalArea: null,
        dateModifiedArea: null,
        dateModifiedSourceArea: null,
        events: [],
      },
      {
        idTicket: 25962,
        ticketSourceId: '4b50b16a1b733010c5bcebd5624bcbfc',
        ticketSourceNumber: 'INC0010070',
        createdDate: '2021-11-16T12:03:06',
        modifiedDate: '2021-11-16T12:03:06',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'virus en correo',
        description: 'He recibido este correo que creemos puede ser un virus.',
        categoryLevel2: 'Hardware_PC',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-16T12:04:37',
        dateModifiedSourceArea: '2021-11-16T12:04:38',
        events: [],
      },
      {
        idTicket: 25961,
        ticketSourceId: 'f48fa92a1b733010c5bcebd5624bcbfd',
        ticketSourceNumber: 'INC0010069',
        createdDate: '2021-11-16T11:59:24',
        modifiedDate: '2021-11-16T11:59:24',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'alta usuario nuevo',
        description: 'Se solicita el alta de los siguiente usuarios.\r\n\r\n',
        categoryLevel2: 'CRM',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'RESTO',
        originalArea: 'MICRO',
        dateModifiedArea: '2021-11-16T12:00:37',
        dateModifiedSourceArea: '2021-11-16T12:00:38',
        events: [],
      },
      {
        idTicket: 25960,
        ticketSourceId: 'f44e29e61b733010c5bcebd5624bcbcc',
        ticketSourceNumber: 'INC0010068',
        createdDate: '2021-11-16T11:53:56',
        modifiedDate: '2021-11-16T11:53:56',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'alta usuarios',
        description: 'Se solicita el alta de los siguiente usuarios.\r\n\r\n',
        categoryLevel2: 'SAP',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'RESTO',
        originalArea: 'MICRO',
        dateModifiedArea: '2021-11-16T11:54:39',
        dateModifiedSourceArea: '2021-11-16T11:54:40',
        events: [],
      },
      {
        idTicket: 25959,
        ticketSourceId: 'a0e729621b733010c5bcebd5624bcbf5',
        ticketSourceNumber: 'INC0010067',
        createdDate: '2021-11-16T11:26:04',
        modifiedDate: '2021-11-22T13:45:39',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'permisos Siebel',
        description: 'Necesitamos que el usuario CC382 tenga en Siebel perfil de jefe de equipo. Por defecto que tenga el perfil de Jefe de equipo',
        categoryLevel2: 'SAP',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'RESTO',
        originalArea: null,
        dateModifiedArea: null,
        dateModifiedSourceArea: '2021-11-22T13:45:39',
        events: [],
      },
      {
        idTicket: 25958,
        ticketSourceId: 'f4662dae1b333010c5bcebd5624bcb11',
        ticketSourceNumber: 'INC0010066',
        createdDate: '2021-11-16T11:19:31',
        modifiedDate: '2021-11-16T11:19:31',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'asignación permisos',
        description: 'Podeis asignarle al usuario IBER0001 los mismos permisos en La3 que el usuario F329?',
        categoryLevel2: 'Hardware_PC',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: null,
        dateModifiedArea: null,
        dateModifiedSourceArea: null,
        events: [],
      },
      {
        idTicket: 25957,
        ticketSourceId: 'fe1521ae1b333010c5bcebd5624bcb30',
        ticketSourceNumber: 'INC0010065',
        createdDate: '2021-11-16T11:13:56',
        modifiedDate: '2021-11-16T11:13:56',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'fallo micro auriculares',
        description: 'Micro de auriculares no funciona \\n\\nHola,\\n\\ndesde ayer el micro de los auriculares HP no funciona.',
        categoryLevel2: 'Directorio_Activo',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-16T11:14:38',
        dateModifiedSourceArea: '2021-11-16T11:14:39',
        events: [],
      },
      {
        idTicket: 25956,
        ticketSourceId: '8c05e9ae1b333010c5bcebd5624bcbf1',
        ticketSourceNumber: 'INC0010064',
        createdDate: '2021-11-16T11:13:27',
        modifiedDate: '2021-11-16T11:13:27',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'bloqueo fotocopiadora',
        description: 'La fotocopiadora de la sala de presidencia no funciona correctamente. Se bloquea y no hace copias.',
        categoryLevel2: 'Directorio_Activo',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-16T11:14:37',
        dateModifiedSourceArea: '2021-11-16T11:14:38',
        events: [],
      },
      {
        idTicket: 25955,
        ticketSourceId: 'a8a321ae1b333010c5bcebd5624bcb08',
        ticketSourceNumber: 'INC0010063',
        createdDate: '2021-11-16T11:07:29',
        modifiedDate: '2021-11-16T11:07:29',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'nuria',
        assignedTo: '',
        summary: 'posible virus en correo electrónico',
        description: 'He recibido este correo que creemos puede ser un virus.',
        categoryLevel2: 'Software_PC',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-16T11:10:37',
        dateModifiedSourceArea: '2021-11-16T11:10:41',
        events: [],
      },
      {
        idTicket: 25954,
        ticketSourceId: '65ee91da1b7f3010c5bcebd5624bcb38',
        ticketSourceNumber: 'INC0010062',
        createdDate: '2021-11-15T16:08:19',
        modifiedDate: '2021-11-15T16:08:19',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'admin',
        assignedTo: '',
        summary: 'virus',
        description: 'He recibido este correo que creemos puede ser un virus.',
        categoryLevel2: 'Gestion de Usuarios',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-15T16:10:37',
        dateModifiedSourceArea: '2021-11-15T16:10:38',
        events: [],
      },
      {
        idTicket: 25953,
        ticketSourceId: '326e919a1b7f3010c5bcebd5624bcbbe',
        ticketSourceNumber: 'INC0010061',
        createdDate: '2021-11-15T16:06:13',
        modifiedDate: '2021-11-15T16:06:13',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'admin',
        assignedTo: '',
        summary: 'posible virua',
        description: 'he recibido un posible virus',
        categoryLevel2: 'SAP',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'RESTO',
        originalArea: null,
        dateModifiedArea: null,
        dateModifiedSourceArea: null,
        events: [],
      },
      {
        idTicket: 25952,
        ticketSourceId: '0cbd5d5a1b7f3010c5bcebd5624bcb47',
        ticketSourceNumber: 'INC0010060',
        createdDate: '2021-11-15T16:03:02',
        modifiedDate: '2021-11-15T16:03:02',
        status: 'New',
        priority: '3 - Low',
        reportedBy: 'admin',
        assignedTo: 'ADMINISTRACION_BBDD',
        summary: 'Otro virus',
        description: 'He recibido este correo que creemos puede ser un virus.',
        categoryLevel2: 'Directorio_Activo',
        originalCategoryLevel2: null,
        dateModifiedCategoryLevel2: null,
        dateModifiedSourceCategoryLevel2: null,
        categoryLevel3: null,
        originalCategoryLevel3: null,
        dateModifiedCategoryLevel3: null,
        dateModifiedSourceCategoryLevel3: null,
        area: 'MICRO',
        originalArea: 'RESTO',
        dateModifiedArea: '2021-11-15T16:04:38',
        dateModifiedSourceArea: '2021-11-15T16:04:39',
        events: [],
      },
    ],
    assignmentGroup: [],
  },
  getters: {},
  mutations: {
    logged(state, payload) {
      state.aiops.logged = payload
    },
    setShowWelcome(state, payload) {
      state.aiops.showWelcome = payload
    },
    setRefreshToken(state, payload) {
      state.refreshToken = payload
    },
    setConfigService(state, payload) {
      state.configuredService = payload
    },
    setTotalTickets(state, payload) {
      state.totalTickets = payload
    },
    setConfigList(state, payload) {
      state.configList = payload
    },
    setAssignmentGroup(state, payload) {
      state.assignmentGroup = payload
    },
    setUser(state, payload) {
      state.aiops.user = payload
    },
    setDataBreadCrumb(state, payload) {
      state.dataBreadCrumb = payload
    },
  },
  actions: {
    logout({ commit }) {
      sessionStorage.removeItem(jwtConfig.storageTokenKeyName)
      sessionStorage.removeItem(jwtConfig.storageRefreshTokenKeyName)

      commit('logged', false)
      // Redirect to login page
      router.push({ name: 'aiops-auth-login' })
    },
    login({ commit }, modelLog) {
      return new Promise((resolve, reject) => {
        useJwt.login(modelLog)
          .then(response => {
            const { data } = response
            // data.role = 'client'
            if (data.roles) {
              data.ability = data.roles.map(role => ({ action: role }))
            }
            const timeToCall = data.expires_in - 2
            useJwt.setToken(data.access_token)
            useJwt.setRefreshToken(data.refresh_token)
            sessionStorage.setItem('userData', JSON.stringify(data))
            commit('logged', true)
            commit('setRefreshToken', response.data.refresh_token)
            resolve({ timeToCall, data })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    dataAssignmentGroup({ commit }) {
      return new Promise((resolve, reject) => {
        useJwt.getAssignmentGroup()
          .then(response => {
            const { data } = response
            const assignmentGroup = data
            commit('setAssignmentGroup', assignmentGroup)
            resolve(assignmentGroup)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    refreshToken({ commit }) {
      const refreshToken = {
        refresh_token: useJwt.getRefreshToken(),
      }
      return new Promise((resolve, reject) => {
        useJwt.refreshToken(refreshToken)
          .then(response => {
            const { data } = response
            useJwt.setToken(data.access_token)
            useJwt.setRefreshToken(data.refresh_token)
            commit('setRefreshToken', data.refresh_token)
            resolve(data.expires_in)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    configService({ commit }, payloadConfig) {
      return new Promise((resolve, reject) => {
        useJwt.configService(payloadConfig)
          .then(response => {
            const res = 'ok'
            commit('setConfigService', res)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    dataToDataSource({ commit }, _data) {
      return new Promise((resolve, reject) => {
        useJwt.getTickets(_data)
          .then(response => {
            const { data } = response
            const { total, tickets } = data
            let lastMmodifiedDate = null
            if (tickets.length > 0) {
              const { modifiedDate } = tickets[0]
              lastMmodifiedDate = modifiedDate ? formatDate(modifiedDate) : null
            }
            commit('setTotalTickets', total)
            commit('setTickets', tickets)
            resolve({ total, lastMmodifiedDate })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getConfigList({ commit }) {
      return new Promise((resolve, reject) => {
        useJwt.getConfigList()
          .then(response => {
            commit('setConfigList', response)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    showWelcomeAssist({ commit }, dato) {
      commit('setShowWelcome', dato)
    },
    showUser({ commit }, dato) {
      commit('setUser', dato)
    },
    dataBreadCrumb({ commit }, data) {
      commit('setDataBreadCrumb', data)
    },
    errorRequest({ commit }) {
      sessionStorage.removeItem(jwtConfig.storageTokenKeyName)
      sessionStorage.removeItem(jwtConfig.storageRefreshTokenKeyName)
      sessionStorage.removeItem('userData')
      localStorage.removeItem('rowsLimit')
      localStorage.removeItem('offSet')
      localStorage.removeItem('queryFilter')
      localStorage.removeItem('sort')
      localStorage.removeItem('filterOK')
      localStorage.removeItem('filtersTable')
      localStorage.removeItem('filterBreadCrumb')
      commit('logged', false)
      commit('setRefreshToken', undefined)
      if (router.history.current.name !== 'aiops-auth-login') {
        router.push({ name: 'aiops-auth-login' })
      }
    },
  },
}
