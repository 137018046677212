import { chartColors, chartColorsRadar } from '@/@core/utils/aiops/chartConfig'

export default function utilsAiops() {
  const formatDate = fullDate => {
    const date = new Date(fullDate)
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    const year = date.getFullYear()
    const hour = date.toLocaleTimeString('it-IT')
    const createdDate = `${day}/${month}/${year}`

    return `${createdDate} ${hour}`
  }

  const formatDesc = _text => {
    let newDescription = ''
    if (_text.length > 32) {
      newDescription = _text.slice(0, 31)
      return `${newDescription}...`
    }

    newDescription = _text

    return `${newDescription}`
  }

  const calculatePercent = (_value, _ticketsLength) => {
    const numOfTickets = _ticketsLength
    const totalPercent = 100
    const percent = (totalPercent * _value) / numOfTickets
    const percentFix = percent.toFixed(0)
    return percentFix
  }

  const modifyEmptyVal = (val, catLevelVal) => {
    const objItem = val
    if (objItem[catLevelVal] === '') {
      objItem[catLevelVal] = 'Other'
    }
    return objItem
  }

  const filterByCatLevel = (_tickets, catLevel, catLevelVal) => {
    const arrDataCatLevel = _tickets.filter(item => item[catLevel] === catLevelVal)
    return arrDataCatLevel
  }

  const dataGraphCategory = (_tickets, levelCategory, typeGrah) => {
    const arrColorsGraphs = Object.values(chartColors)
    const pieChart = {
      options: {
        responsive: true,
        maintainAspectRatio: true,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 50,
        legend: { display: false },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[0].labels[tooltipItem.index] || ''
              const value = data.datasets[0].data[tooltipItem.index]
              const output = ` ${label} : ${value} %`
              return output
            },
          },
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: '#ffffff',
          titleFontColor: chartColors.accentRed,
          bodyFontColor: chartColors.mineralGreen,
        },
      },
      data: {
        datasets: [
          {
            labels: [],
            data: [],
            backgroundColor: [],
            borderWidth: 2,
            pointStyle: 'rectRounded',
          },
        ],
      },
    }
    const horizontalBarChart = {
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'top',
          },
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: '#ffffff',
          titleFontColor: '#000000',
          bodyFontColor: '#000000',
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                zeroLineColor: chartColors.grid_line_color,
                borderColor: 'transparent',
                color: chartColors.grid_line_color,
                drawTicks: false,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                min: 0,
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            borderColor: 'transparent',
            barThickness: 15,
          },
        ],
      },
    }
    const dataChart = typeGrah === 'horizontal' ? horizontalBarChart : pieChart
    const arrayLabels = []
    const stockData = []
    const selectData = [
      { value: null, text: 'Select category' },
    ]
    const arrGraphElem = _tickets.map(item => modifyEmptyVal(item, levelCategory))
    arrGraphElem.forEach(item => {
      if (item[levelCategory]) {
        arrayLabels.push(item[levelCategory])
      }
    })
    const countLevel = arrayLabels.reduce((prev, cur) => {
      // eslint-disable-next-line no-param-reassign
      prev[cur] = (prev[cur] || 0) + 1
      return prev
    }, {})
    const labelsGraph = Object.keys(countLevel)
    const indexLabel = labelsGraph.indexOf('?') || labelsGraph.indexOf('')
    if (indexLabel !== -1) {
      labelsGraph[indexLabel] = 'Other'
    }
    const dataGraph = Object.values(countLevel)
    const percentDataGraph = dataGraph.map(item => calculatePercent(item, arrGraphElem.length))
    for (let i = 0; i < labelsGraph.length; i += 1) {
      const stock = {
        symbol: 'BarChart2Icon',
        percentage: percentDataGraph[i],
        color: arrColorsGraphs[i],
      }
      stock[levelCategory] = labelsGraph[i]
      stockData.push(stock)
      const selectItem = {
        value: labelsGraph[i],
        text: labelsGraph[i],
      }
      selectData.push(selectItem)
    }
    stockData.sort((a, b) => {
      if (a.percentage > b.percentage) {
        return -1
      }
      return 1
    })

    const labelsData = stockData.map(item => item[levelCategory])
    const percentageData = stockData.map(item => item.percentage)
    const colorGraphs = stockData.map(item => item.color)
    if (typeGrah === 'horizontal') {
      dataChart.data.labels = labelsData
    }
    if (typeGrah === 'pie') {
      dataChart.data.labels = labelsData
      dataChart.data.datasets[0].labels = labelsData
    }
    dataChart.data.datasets[0].backgroundColor = colorGraphs
    dataChart.data.datasets[0].data = percentageData
    return { stockData, dataChart, selectData }
  }

  const getSubcategories = (modelTree, subcategory = 'modelClass') => {
    const arrayLabels = []
    const selectData = [
      { value: null, text: 'Select value' },
    ]
    if (modelTree?.classes?.length > 0) {
      modelTree.classes.forEach(item => {
        if (item[subcategory]) {
          arrayLabels.push(item[subcategory])
        }
      })
      for (let i = 0; i < arrayLabels.length; i += 1) {
        const selectItem = {
          value: arrayLabels[i],
          text: arrayLabels[i],
        }
        selectData.push(selectItem)
      }
    }
    return selectData
  }

  const getSubcategoriesL2 = (categoryTree, area) => {
    let selectData
    categoryTree.forEach(cat => {
      if (cat.value === area && cat.childNodes.length > 0) {
        const childCategories = cat.childNodes.map(childNode => ({ value: childNode.value, text: childNode.label }))
        console.log(childCategories)
        selectData = [
          { value: null, text: 'Select value' },
          ...childCategories,
        ]
      }
    })
    return selectData
  }

  const getSubcategoriesL2ToFilter = categoryTree => {
    const selectData = []
    categoryTree.forEach(cat => {
      if (cat.childNodes.length > 0) {
        // const childCategories = cat.childNodes.map(childNode => ({ value: childNode.value, text: childNode.label }))
        // selectData.push(childCategories[0])
        cat.childNodes.forEach(childNode => {
          const childCategory = { value: childNode.value, text: childNode.label }
          selectData.push(childCategory)
        })
      }
    })
    const subCatList = []
    selectData.forEach(subCat => {
      if (!subCatList.find(item => item.value === subCat.value)) {
        subCatList.push(subCat)
      }
    })
    const subCatListSort = subCatList.sort((a, b) => {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    })
    subCatListSort.unshift({ value: null, text: 'Select subcategory' })
    return subCatListSort
  }

  const removeAccents = str => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  const getRadarChart = (_sourceId, embedding) => {
    const arrayItemsVector = []
    for (let i = 0; i < 20; i += 1) {
      arrayItemsVector.push(i)
    }
    const arrEmbedding = embedding.map(vectorItem => {
      let vector = vectorItem
      vector = (vector + 1) / 2
      return vector
    })
    // const highValue = Math.max(...arrEmbedding)
    const chartRadar = {
      series: [
        {
          name: _sourceId,
          data: arrEmbedding,
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1,
          },
        },
        legend: {
          show: true,
          fontSize: '14px',
          fontFamily: 'Open Sans, Helvetica, Arial, serif',
        },
        yaxis: {
          show: false,
          max: 1,
          min: 0,
          tickAmount: 10,
        },
        colors: [chartColorsRadar.area.series1, chartColorsRadar.area.series1],
        xaxis: {
          categories: arrayItemsVector,
        },
        fill: {
          opacity: 0.2,
        },
        stroke: {
          show: true,
          width: 3,
        },
        markers: {
          size: 4,
          colors: ['#fff'],
          strokeColor: chartColorsRadar.area.series1,
          strokeWidth: 1,
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
      },
    }
    return chartRadar
  }

  const loadingProccessSpinner = proccess => {
    const appLoading = document.getElementById('loading-bg-proccess')
    if (appLoading && proccess) {
      appLoading.style.display = 'block'
    } else {
      appLoading.style.display = 'none'
    }
  }

  const loading = load => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading && load) {
      appLoading.style.display = 'block'
    } else {
      appLoading.style.display = 'none'
    }
  }

  const buildQuery = (_string, item) => {
    let mainString = _string
    let stringQuery = ''
    if (!item.fieldValue) {
      stringQuery = `${item.field}${item.condition}`
      mainString = mainString.concat(stringQuery)
      if (item.children?.length > 0) {
        item.children.forEach(itemChildren => {
          stringQuery = '_OR_'
          mainString = mainString.concat(stringQuery)
          mainString = buildQuery(mainString, itemChildren)
        })
      }
      return mainString
    }
    stringQuery = `${item.field}${item.condition}'${removeAccents(item.fieldValue.replace('/', '%2F'))}'`
    mainString = mainString.concat(stringQuery)
    if (item.children?.length > 0) {
      item.children.forEach(itemChildren => {
        stringQuery = '_OR_'
        mainString = mainString.concat(stringQuery)
        mainString = buildQuery(mainString, itemChildren)
      })
    }
    return mainString
  }

  const setConfigHome = (rowsLimit, offSet, queryFilter, sort, filtersTable, filterBreadCrumb, filterOK) => {
    localStorage.setItem('rowsLimit', rowsLimit)
    localStorage.setItem('offSet', offSet)
    localStorage.setItem('queryFilter', queryFilter)
    localStorage.setItem('sort', sort)
    localStorage.setItem('filterOK', filterOK)
    localStorage.setItem('filtersTable', JSON.stringify(filtersTable))
    localStorage.setItem('filterBreadCrumb', JSON.stringify(filterBreadCrumb))
  }

  const setMessage = (toaster, variant = 'warning', message = 'Something was wrong', title = 'Error in the process', icon = undefined) => {
    toaster.toast(message, {
      title,
      icon,
      variant,
      solid: true,
    })
  }

  return {
    dataGraphCategory,
    getSubcategories,
    getSubcategoriesL2,
    filterByCatLevel,
    formatDate,
    formatDesc,
    getRadarChart,
    removeAccents,
    loadingProccessSpinner,
    loading,
    buildQuery,
    getSubcategoriesL2ToFilter,
    setConfigHome,
    setMessage,
  }
}
