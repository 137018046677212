import useJwt from '@/auth/aiops/useJwt'
// import utilsAiops from '@/@core/utils/aiops/utils'

// const { formatDate } = utilsAiops()

export default {
  namespaced: true,
  state: {
    proccessCreateuser: true,
  },
  getters: {},
  mutations: {
    proccessCreateuser(state, payload) {
      state.proccessCreateuser = payload
    },
  },
  actions: {
    knowledgeBaseList() {
      return new Promise((resolve, reject) => {
        useJwt.getKnowledgeBaseList()
          .then(response => {
            const { data } = response
            resolve(data)
          })
          .catch(error => {
            const { data } = error
            reject(data.data)
          })
      })
    },
    createKnowledgeBase({ commit }, payload) {
      return new Promise((resolve, reject) => {
        useJwt.createKnowledgeBase(payload)
          .then(response => {
            const { data } = response
            commit('proccessCreateuser', true)
            resolve(data)
          })
          .catch(error => {
            const { data } = error
            reject(data.data)
          })
      })
    },
    updateKnowledgeBase({ commit }, payload) {
      return new Promise((resolve, reject) => {
        useJwt.updateKnowledgeBase(payload)
          .then(response => {
            const { data } = response
            commit('proccessCreateuser', true)
            resolve(data)
          })
          .catch(error => {
            const { data } = error
            reject(data.data)
          })
      })
    },
    deleteKnowledgeBase({ commit }, payload) {
      return new Promise((resolve, reject) => {
        useJwt.deleteKnowledgeBase(payload)
          .then(response => {
            const { data } = response
            commit('proccessCreateuser', true)
            resolve(data)
          })
          .catch(error => {
            const { data } = error
            reject(data.data)
          })
      })
    },
  },
}
